import {
  Box,
  Button,
  Divider,
  Flex,
  HStack,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Stack,
  Text,
  Tooltip,
  Tab,
  Link,
  Tabs,
  TabList,
  useDisclosure
} from '@chakra-ui/react'
import { IconTableDown, IconX, IconCoin, IconHash, IconBuilding, IconBriefcase, IconStack2 } from '@tabler/icons-react'
import CompanyAvatar from '../../../ui/CompanyAvatar'
import { ColumnDef, RowSelectionState } from '@tanstack/react-table'
import React, { useCallback, useState } from 'react'
import { useDebounce } from 'use-debounce'
import { Company } from '../../../../types/Profile'
import { filteredProspectsPath } from '../../../data/use-prospects'
import { UrlFilterParams } from '../../../data/use-url-filters'
import { Breadcrumb } from '../../../ui/Breadcrumb'
import { Card } from '../../../ui/Card'
import { BulkActionBar } from '../../../ui/BulkActionBar'
import { SearchIcon } from '../../../ui/icons/SearchIcon'
import { projectPath } from '../../../ui/ProjectsContext'
import useLocation from '../../../ui/useLocation'
import useUpdateEffect from '../../../ui/useUpdateEffect'
import { FacetFilters } from '../../accounts'
import { FilterState } from '../../personas/persona-filters'
import { CustomTable } from '../../../ui/Table'
import type { PageMeta } from '../../../../types/PageMeta'
import { TableFooter } from '../../../ui/TableFooter'
import { FilterPopover, FilterPopoverProps } from '../../prospector/components/FilterPopover'
import { AddToListModal } from '../../lists/components/AddToListModal'
import { ImportListPrompt } from './ImportListPrompt'
import { RenameListModal } from './RenameListModal'

const columns: ColumnDef<Company>[] = [
  {
    id: 'company',
    accessorFn: (row) => row.name || row.domain,
    size: 300,
    header: 'Company',
    cell: ({ row, getValue }) => {
      if (!getValue()) {
        return null
      }

      return (
        <HStack spacing={2} isTruncated>
          <CompanyAvatar size="xs" name={row.original.name} domain={row.original.domain} />
          <Text fontSize="15px" minWidth="100px" lineHeight="1.2" fontWeight="semibold" isTruncated>
            {row.original.name || row.original.domain}
          </Text>
        </HStack>
      )
    }
  },
  {
    id: 'category.industry',
    accessorKey: 'category.industry',
    header: 'Industry',
    cell: ({ getValue }) => <Box isTruncated>{getValue()}</Box>
  },
  {
    id: 'metrics.employeesRange',
    accessorKey: 'metrics.employeesRange',
    header: 'Employees',
    size: 100
  },
  {
    id: 'metrics.estimatedAnnualRevenue',
    accessorKey: 'metrics.estimatedAnnualRevenue',
    header: 'Est. Revenue',
    size: 100
  },
  {
    id: 'geo',
    accessorKey: 'geo',
    header: 'Location',
    cell: ({ getValue }) => {
      const geo = getValue() as Company['geo']
      return [geo?.state, geo?.country].filter(Boolean).join(', ')
    }
  }
]

interface Props {
  companies: Company[]
  page_meta: PageMeta
  facets: UrlFilterParams
  listId: string
}

function FilterMenu(props: React.PropsWithChildren<FilterPopoverProps>) {
  return (
    <Box paddingY={1.5} paddingX={1.5}>
      <FilterPopover {...props} showPreview isCompaniesView />
    </Box>
  )
}

export function ExploreCompaniesView(props: Props) {
  const { facets } = props
  const { facetFilters } = facets
  const listId = props.listId

  const contextPathsElements = window.location.pathname.split('/')
  const contextPath = contextPathsElements.slice(0, contextPathsElements.length - 1).join('/')

  const tabs = [
    { label: 'People', href: '' },
    { label: 'Companies', href: '/companies' },
    { label: 'Saved', href: '/saved', isDisabled: true }
  ]

  const [selectedRows, setSelectedRows] = useState<RowSelectionState>({})
  const addToList = useDisclosure()
  const renameListModal = useDisclosure()
  const location = useLocation()
  const savedProspectsSelected = location.pathname.endsWith('/saved')

  const applyFilters = facets.applyFilters
  const clearFilters = facets.clearFilters

  const [searchQuery, setSearchQuery] = useState(facets.query || '')
  const [debouncedSearchQuery] = useDebounce(searchQuery, 300)

  const selections = Object.entries(selectedRows)
    .filter(([_id, selected]) => selected)
    .map(([id]) => id)

  const onFilterChange = useCallback(
    (filters: Record<string, FilterState>) => {
      // convert the filters to the format that the url filters expect
      const facetFilters: FacetFilters = {}

      for (const [facet, filter] of Object.entries(filters)) {
        if (filter.operator === 'must') {
          facetFilters[facet] = filter.values
        } else if (filter.operator === 'must_not') {
          facetFilters[facet] = { not: filter.values }
        }
      }

      applyFilters(facetFilters)
    },
    [applyFilters]
  )

  useUpdateEffect(() => {
    if (debouncedSearchQuery !== facets.query) {
      facets.setQuery(debouncedSearchQuery)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchQuery])

  const handleRenameListClick = () => {
    renameListModal.onOpen()
  }
  const [listRenamed, setListIsRenamed] = useState(false)

  const handleListUpdate = () => {
    setListIsRenamed(true)
  }

  return (
    <Flex flex="1 1 auto" overflow="hidden">
      <Flex flexDirection="column">
        <Tabs
          size="sm"
          variant="line"
          isManual
          defaultIndex={1}
          paddingY={2}
          paddingX={0}
          borderRight="1px solid"
          borderColor="gray.200"
          align="center"
          isFitted
        >
          <TabList>
            {tabs.map((tab) => (
              <Tab key={tab.label} isDisabled={tab.isDisabled}>
                {tab.isDisabled ? (
                  <Tooltip label="Tab under construction" placement="bottom-end">
                    <Link href={`${contextPath}${tab.href}`}>{tab.label}</Link>
                  </Tooltip>
                ) : (
                  <Link href={`${contextPath}${tab.href}`}>{tab.label}</Link>
                )}
              </Tab>
            ))}
          </TabList>
        </Tabs>
        <Box
          bg="white"
          borderRight="1px solid"
          borderColor="gray.200"
          padding={6}
          paddingBottom={0}
          minW="320px"
          width="360px"
          overflow="auto"
          display="flex"
          flexDir="column"
          justifyContent="space-between"
          gap={8}
          height="full"
        >
          <Box flex="none" isTruncated>
            <Breadcrumb
              display="flex"
              fontSize="13px"
              paths={[
                {
                  path: projectPath(`/prospector`),
                  title: 'Prospector'
                },
                {
                  path: projectPath(`/prospector/companies`),
                  title: 'Companies'
                }
              ]}
            />
          </Box>
          <Stack>
            <Text fontSize="sm" fontWeight="medium">
              Filters
            </Text>

            <Card padding={0}>
              <Stack divider={<Divider />} spacing={0}>
                <FilterMenu
                  facet="company.category.industry"
                  filters={facetFilters as Record<string, FilterState>}
                  icon={IconBriefcase}
                  onChange={onFilterChange}
                >
                  Industry
                </FilterMenu>
                <FilterMenu
                  facet="company.metrics.employeesRange"
                  filters={facetFilters as Record<string, FilterState>}
                  icon={IconHash}
                  onChange={onFilterChange}
                >
                  Employees
                </FilterMenu>
                <FilterMenu
                  facet="company.metrics.estimatedAnnualRevenue"
                  filters={facetFilters as Record<string, FilterState>}
                  {...facets}
                  icon={IconCoin}
                  onChange={onFilterChange}
                >
                  Est. Revenue
                </FilterMenu>
                <FilterMenu
                  facet="company.tech"
                  filters={facetFilters as Record<string, FilterState>}
                  {...facets}
                  icon={IconStack2}
                  onChange={onFilterChange}
                >
                  Tech Stack
                </FilterMenu>
                <FilterMenu
                  facet="company.geo.city"
                  filters={facetFilters as Record<string, FilterState>}
                  {...facets}
                  icon={IconBuilding}
                  onChange={onFilterChange}
                >
                  City
                </FilterMenu>
                <FilterMenu
                  facet="company.geo.state"
                  filters={facetFilters as Record<string, FilterState>}
                  {...facets}
                  icon={IconBuilding}
                  onChange={onFilterChange}
                >
                  State
                </FilterMenu>
                <FilterMenu
                  facet="company.geo.country"
                  filters={facetFilters as Record<string, FilterState>}
                  {...facets}
                  icon={IconBuilding}
                  onChange={onFilterChange}
                >
                  Country
                </FilterMenu>
              </Stack>
            </Card>
          </Stack>

          <Box marginTop="auto" paddingTop={4} position="sticky" bottom={0} paddingBottom={6} bg="white">
            <Flex gap={3} justifyContent="space-between">
              {Object.keys(facets.facetFilters).length ? (
                <Button w="100%" variant="outline" onClick={clearFilters}>
                  Reset filters
                </Button>
              ) : null}
            </Flex>
          </Box>
        </Box>
      </Flex>
      <Box width="100%" overflow="auto" mt="-2px">
        <HStack paddingY={2} paddingX={2} justifyContent="space-between" spacing="4">
          <Box flex="1">
            <InputGroup size="sm" flex="1 1 100px">
              <InputLeftElement width="7" pointerEvents="none" color="gray.400">
                <SearchIcon boxSize={3.5} />
              </InputLeftElement>
              <Input
                size="sm"
                fontSize="13px"
                background="white"
                outline="none"
                border="none"
                roundedBottom={0}
                focusBorderColor="transparent"
                placeholder="Search"
                paddingLeft={7}
                value={searchQuery}
                onChange={(e) => {
                  setSearchQuery(e.target.value)
                }}
              />

              {searchQuery && (
                <InputRightElement>
                  <IconButton
                    size="xs"
                    aria-label="Clear search"
                    variant="ghost"
                    color="gray.400"
                    _hover={{ color: 'gray.600' }}
                    onClick={() => setSearchQuery('')}
                    icon={<IconX size={16} />}
                  />
                </InputRightElement>
              )}
            </InputGroup>
          </Box>

          <Flex>
            <Tooltip label="Feature under construction" placement="bottom-start">
              <Button
                isDisabled
                size="sm"
                variant="outline"
                colorScheme="lightPurple"
                leftIcon={<Icon as={IconTableDown} size={16} />}
                iconSpacing={1.5}
                mr="2"
                onClick={() => {
                  const url = filteredProspectsPath('props.company.domain', {
                    filters: facets.facetFilters,
                    format: 'csv',
                    search: searchQuery,
                    only_saved: savedProspectsSelected,
                    page: facets.page
                  })
                  window.open(url)
                }}
              >
                Export
              </Button>
            </Tooltip>
          </Flex>
        </HStack>
        <CustomTable
          data={props.companies}
          columns={columns}
          stickyFirstColumn
          enableRowSelection
          selectedRows={selectedRows}
          onRowSelectionChange={setSelectedRows}
        />
        {listId && (
          <>
            <ImportListPrompt
              show={true}
              onRenameListClick={() => handleRenameListClick()}
              listId={listId}
              listIsRenamed={listRenamed}
            />
            <RenameListModal {...renameListModal} onUpdate={() => handleListUpdate()} listId={listId} />
          </>
        )}
        <AddToListModal recordIds={selections} kind="account" {...addToList} />
        <Box paddingX={4}>
          <TableFooter
            word="company"
            pageMeta={props.page_meta}
            page={(facets.page ?? 1) as number}
            setPage={facets.setPage}
            sticky
          />
        </Box>
      </Box>
      <BulkActionBar selectionCount={selections.length ?? 0} onRemoveSelection={() => setSelectedRows({})}>
        <Button variant="outline" size="sm" onClick={addToList.onOpen}>
          Add to List
        </Button>
      </BulkActionBar>
    </Flex>
  )
}
