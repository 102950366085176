import CompanyAvatar from '@app/components/ui/CompanyAvatar'
import {
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  Heading,
  HStack,
  Icon,
  IconButton,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
  useCheckboxGroup,
  useDisclosure,
  UseDisclosureProps
} from '@chakra-ui/react'
import {
  IconAddressBook,
  IconAddressBookOff,
  IconCircleDashedCheck,
  IconMailSearch,
  IconPhonePlus,
  IconRosetteDiscountCheckFilled,
  IconSparkles,
  IconTableDown,
  IconUserSearch
} from '@tabler/icons-react'
import { isEmpty, keyBy } from 'lodash'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { toast } from 'sonner'
import { useActionCableChannel } from '../../../../channels/use-channel'
import { post } from '../../../../lib/api'
import { Account } from '../../../../types/Account'
import { Apps } from '../../../../types/App'
import { Company } from '../../../../types/Profile'
import { useEnrichProspects } from '../../../data/use-enrich'
import { useImportProspectsToCrm } from '../../../data/use-import-to-crm'
import {
  filteredProspectsPath,
  Highlights,
  Prospect,
  useFilteredProspects,
  useSaveProspects,
  useUnsaveProspects
} from '../../../data/use-prospects'
import { useUrlFilters } from '../../../data/use-url-filters'
import Avatar from '../../../ui/Avatar'
import { BulkActionBar } from '../../../ui/BulkActionBar'
import { CardRadioGroup } from '../../../ui/CardRadioGroup'
import { Copyable } from '../../../ui/Copyable'
import EmptyState from '../../../ui/EmptyState'
import { HelpTooltip } from '../../../ui/HelpTooltip'
import { KoalaIcon, LinkedinBoxIcon, SalesforceIcon, SfContactIcon, SfLeadIcon } from '../../../ui/icons'
import { HubSpotIcon } from '../../../ui/icons/HubspotIcons'
import { projectPath } from '../../../ui/ProjectsContext'
import { TableFooter } from '../../../ui/TableFooter'
import { TextEllipsis } from '../../../ui/text-ellipsis'
import { useOverflow } from '../../../ui/useOverflow'
import { humanize } from '../../accounts/facets/filter-cloud'
import { accountPath } from '../../accounts/lib/account-path'
import { useEmailNotFound } from '../../profiles/components/AutoProspectCard'
import { profilePath } from '../../profiles/lib/path'
import { ActionMenu } from '../../prospects/action-menu'
import { Persona } from '../../prospects/personas'
import { OmnisearchCompany } from '../../../data/use-company-search'
import { CompanyBubble } from '../../../ui/CompanyBubble'

interface ProspectsTableProps {
  account: Account
  company?: Company | OmnisearchCompany | null
  persona?: Persona | null
  apps: Apps
}

interface EnrichmentChannelData {
  action: 'started' | 'results'
  prospect_id: string
  email?: null | string
  state?: null | 'unlocked' | 'locked' | 'not_found' | 'auto-unlocked'
  updated_at?: string
}

const rowHover = {
  bg: [undefined, 'gray.50']
}

// TODO split out the table from the data fetching
export function ProspectsTableV2(props: ProspectsTableProps) {
  const { company, persona } = props

  const [emailLoadingState, setEmailLoadingState] = useState({})
  const [phoneLoadingState, setPhoneLoadingState] = useState({})
  const [verificationLoadingState, setVerificationLoadingState] = useState({})
  const [emailNotFound, setEmailNotFound] = useEmailNotFound({})

  const facets = useUrlFilters({ initialRange: null })

  const filters = useMemo(() => facets.facetFilters, [facets.facetFilters])
  const search = facets.query
  const page = facets.page

  const savedProspectsSelected = window.location.pathname.endsWith('/saved')

  const domain = company?.domain || ''

  const prospects = useFilteredProspects(domain, {
    page,
    perPage: 20,
    persona: persona?.id,
    only_saved: savedProspectsSelected,
    filters,
    search
  })

  const [pageMeta, setPageMeta] = useState(prospects.data?.page_meta)
  const [localProspects, setLocalProspects] = useState<Prospect[]>([])
  const [highlights, setHighlights] = useState<Highlights<'job_title.analyzed' | 'full_name.analyzed'>>({})

  const checkboxes = useCheckboxGroup()
  const selected = checkboxes.value as string[]

  useEffect(() => {
    if (prospects.data && !prospects.isLoading && !prospects.isFetching) {
      setHighlights(prospects.data.highlights ?? {})
      setLocalProspects(prospects.data.prospects ?? [])
      setPageMeta(prospects.data.page_meta)
      checkboxes.setValue([])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prospects.data, prospects.isLoading, prospects.isFetching])

  const onUnlockEmail = useCallback(
    (prospect: Prospect) => {
      setEmailLoadingState((prev) => ({ ...prev, [prospect.id]: true }))
      let toastId: string | number | undefined

      const timer = setTimeout(() => {
        toastId = toast('Searching for prospect data against multiple data sources. This may take a few seconds...', {
          duration: 10_000,
          dismissible: true
        })
      }, 2500)

      post<{ prospect: Prospect }>(accountPath({ domain: domain }, `/prospects/${prospect.id}/unlock`))
        .then((res) => {
          clearTimeout(timer)

          setLocalProspects((prev) => {
            return prev.map((p) => {
              if (p.id === prospect.id) {
                return {
                  ...p,
                  ...res.prospect
                }
              }
              return p
            })
          })

          if (toastId) {
            toast.dismiss(toastId)
            toastId = undefined
          }

          if (res.prospect.email) {
            toast.success('Prospect unlocked')
          } else {
            toast.warning(`Could not find email for ${humanize(res.prospect.first_name ?? '')}`)
            setEmailNotFound((prev) => ({ ...prev, [prospect.id]: true }))
          }
        })
        .catch((err) => {
          toast.error("Couldn't find email for prospect: " + err.message)
        })
        .finally(() => {
          clearTimeout(timer)
          if (toastId) {
            toast.dismiss(toastId)
            toastId = undefined
          }

          setEmailLoadingState((prev) => ({ ...prev, [prospect.id]: false }))
        })
    },
    [domain, setEmailNotFound]
  )

  const onUnlockPhone = useCallback(
    (prospect: Prospect) => {
      setPhoneLoadingState((prev) => ({ ...prev, [prospect.id]: true }))
      post<{ prospect: Prospect }>(accountPath({ domain: domain }, `/prospects/${prospect.id}/unlock-phone`))
        .then((res) => {
          setLocalProspects((prev) => {
            return prev.map((p) => {
              if (p.id === prospect.id) {
                return {
                  ...p,
                  ...res.prospect
                }
              }
              return p
            })
          })

          if (res.prospect.phone_state === 'unlocked') {
            toast.success('Phone number unlocked')
          } else if (res.prospect.phone_state === 'not_found') {
            toast.warning('Phone number not found')
          }
        })
        .catch(() => {
          toast.error('Failed to unlock phone number')
        })
        .finally(() => {
          setPhoneLoadingState((prev) => ({ ...prev, [prospect.id]: false }))
        })
    },
    [domain]
  )

  const onVerifyEmail = useCallback(
    (prospect: Prospect) => {
      setVerificationLoadingState((prev) => ({ ...prev, [prospect.id]: true }))
      post<{ prospect: Prospect }>(accountPath({ domain: domain }, `/prospects/${prospect.id}/verify-email`))
        .then((res) => {
          if (res.prospect.verification_status === 'verified') {
            toast.success('Email verified')
          } else {
            toast.warning('Email could not be verified')
          }

          setLocalProspects((prev) => {
            return prev.map((p) => {
              if (p.id === prospect.id) {
                return {
                  ...p,
                  verification_status: res.prospect.verification_status
                }
              }
              return p
            })
          })
        })
        .catch(() => {
          toast.error('Failed to verify email')
        })
        .finally(() => {
          setVerificationLoadingState((prev) => ({ ...prev, [prospect.id]: false }))
        })
    },
    [domain]
  )

  const trackCopyEmail = useCallback(
    (prospect: Prospect) => {
      window.ko?.track('Prospect Email Copied', {
        email: prospect.email,
        prospected_profile_id: prospect.id,
        company: domain,
        context: 'explore'
      })
    },
    [domain]
  )

  const trackCopyPhone = useCallback(
    (prospect: Prospect) => {
      window.ko?.track('Prospect Phone Copied', {
        email: prospect.email,
        prospected_profile_id: prospect.id,
        company: domain,
        context: 'explore'
      })
    },
    [domain]
  )

  const { mutateAsync: saveProspectsAsync } = useSaveProspects()
  const { mutateAsync: unsaveProspectsAsync } = useUnsaveProspects()
  const { mutateAsync: enrichProspectsAsync } = useEnrichProspects()
  const [saving, setSaving] = useState({})

  const saveProspects = useCallback(
    async (prospectIds: string[]) => {
      const pluralized = prospectIds.length > 1 ? 'Prospects' : 'Prospect'
      try {
        setSaving((prev) => {
          const next = { ...prev }
          prospectIds.forEach((id) => {
            next[id] = true
          })
          return next
        })
        await saveProspectsAsync({ prospectIds })
        setLocalProspects((prev) => {
          return prev.map((p) => {
            if (prospectIds.includes(p.id)) {
              return {
                ...p,
                saved: true
              }
            }
            return p
          })
        })
        toast.success(`${pluralized} saved`)
      } catch (err) {
        toast.error(`Failed to save ${pluralized.toLowerCase()}`)
      } finally {
        setSaving((prev) => {
          const next = { ...prev }
          prospectIds.forEach((id) => {
            next[id] = false
          })
          return next
        })
      }
    },
    [saveProspectsAsync]
  )

  const unsaveProspects = useCallback(
    async (prospectIds: string[]) => {
      const pluralized = prospectIds.length > 1 ? 'Prospects' : 'Prospect'
      try {
        setSaving((prev) => {
          const next = { ...prev }
          prospectIds.forEach((id) => {
            next[id] = true
          })
          return next
        })
        await unsaveProspectsAsync({ prospectIds })
        setLocalProspects((prev) => {
          return prev.map((p) => {
            if (prospectIds.includes(p.id)) {
              return {
                ...p,
                saved: false
              }
            }
            return p
          })
        })
        toast.success(`${pluralized} removed from Saved Prospects`)
      } catch (err) {
        toast.error(`Failed to remove ${pluralized.toLowerCase()} from Saved Prospects`)
      } finally {
        setSaving((prev) => {
          const next = { ...prev }
          prospectIds.forEach((id) => {
            next[id] = false
          })
          return next
        })
      }
    },
    [unsaveProspectsAsync]
  )

  const hasData = useMemo(() => localProspects.length > 0, [localProspects.length])

  const noData = useMemo(
    () => !prospects.isLoading && !prospects.isFetching && localProspects.length === 0,
    [prospects.isLoading, prospects.isFetching, localProspects.length]
  )

  const hasFilters = Boolean(props.persona?.id) || !isEmpty(filters)

  const { scrollRef, overflowLeft } = useOverflow([hasData])

  // if the selected rows are all saved, disable the save button
  const selectedAllSaved = useMemo(() => {
    return checkboxes.value.length > 0 && checkboxes.value.every((id) => localProspects.find((p) => p.id === id)?.saved)
  }, [checkboxes.value, localProspects])

  const selectedAllEnriched = useMemo(() => {
    return (
      checkboxes.value.length > 0 &&
      checkboxes.value.every((id) => {
        const prospect = localProspects.find((p) => p.id === id)
        if (!prospect) return false
        if (prospect.email) return true
        if (['unlocked', 'auto-unlocked', 'not_found'].includes(prospect.unlock_state)) return true
        return false
      })
    )
  }, [checkboxes.value, localProspects])

  const enrichSelected = useCallback(async () => {
    setEmailLoadingState((prev) => {
      const loaders = {}
      for (const id of checkboxes.value as string[]) {
        loaders[id] = true
      }

      return { ...prev, ...loaders }
    })

    try {
      const res = await enrichProspectsAsync({ prospectIds: checkboxes.value as string[] })
      const updates = keyBy(res.prospects, 'id')
      const completed = res.prospects
        .filter((p) => p.email && ['unlocked', 'auto-unlocked', 'not_found'].includes(p.unlock_state))
        .map((p) => p.id)

      // if we already have emails we can show them immediately
      setLocalProspects((prev) => {
        return prev.map((p) => {
          if (checkboxes.value.includes(p.id)) {
            return {
              ...p,
              ...(updates[p.id] || {})
            }
          }
          return p
        })
      })

      if (completed.length > 0) {
        setEmailLoadingState((prev) => {
          const loadingState = {}
          for (const id of completed) {
            loadingState[id] = false
          }

          return { ...prev, ...loadingState }
        })
      }
    } catch (_err) {
      toast.error('Failed to request bulk enrichment', {
        description: 'Please try again later or contact support@getkoala.com'
      })
    }
  }, [enrichProspectsAsync, checkboxes.value])

  const onImport = useCallback((prospects: Partial<Prospect>[]) => {
    const prospectsById = keyBy(prospects, 'id')

    setLocalProspects((prev) => {
      return prev.map((p) => {
        const updated = prospectsById[p.id]
        if (updated) {
          return {
            ...p,
            ...updated
          }
        }
        return p
      })
    })
  }, [])

  useActionCableChannel<EnrichmentChannelData>('EnrichmentChannel', { company: domain }, (data) => {
    if (data.action === 'started' && data.prospect_id) {
      setEmailLoadingState((prev) => ({ ...prev, [data.prospect_id]: true }))
    } else if (data.action === 'results' && data.prospect_id) {
      setLocalProspects((prev) => {
        return prev.map((p) => {
          if (p.id === data.prospect_id && (!p.updated_at || !data.updated_at || p.updated_at < data.updated_at)) {
            return {
              ...p,
              email: data.email ?? null,
              unlock_state: data.state!,
              updated_at: data.updated_at!
            }
          }
          return p
        })
      })
      setEmailLoadingState((prev) => ({ ...prev, [data.prospect_id]: false }))
    }
  })

  const addToCrm = useDisclosure()
  const addToSequence = useDisclosure()

  return (
    <Box w="100%">
      {hasData ? (
        <TableContainer
          ref={scrollRef}
          position="relative"
          className={overflowLeft ? 'scrolled' : undefined}
          width="100%"
        >
          <Table size="sm" width="100%" height="1px">
            <Thead>
              <Tr height="38px">
                <Th className="sticky-column">
                  <Flex alignItems="center" gap={2}>
                    <Checkbox
                      size="md"
                      marginRight={1}
                      isChecked={checkboxes.value.length === localProspects.length}
                      onChange={() => {
                        checkboxes.setValue((prev) =>
                          prev.length === localProspects.length ? [] : localProspects.map((p) => p.id)
                        )
                      }}
                    />
                    <Text>Person</Text>
                  </Flex>
                </Th>
                <Th>Company</Th>
                <Th>Role</Th>
                <Th>Email</Th>
                <Th>Phone</Th>
                <Th>Location</Th>
              </Tr>
            </Thead>
            <Tbody>
              {localProspects.map((prospect) => (
                <Tr
                  key={prospect.id}
                  role="group"
                  fontSize="sm"
                  opacity={prospects.isLoading ? 0.5 : 1}
                  bg={selected.includes(prospect.id) ? 'purple.50' : undefined}
                  _hover={selected.includes(prospect.id) ? undefined : rowHover}
                >
                  <Td
                    className="sticky-column"
                    height="100%"
                    w="1px"
                    bg={selected.includes(prospect.id) ? 'purple.50' : undefined}
                  >
                    <Flex alignItems="center" gap={2} paddingY={1} minW="360px" maxW="440px">
                      <Checkbox size="md" marginRight={1} {...checkboxes.getCheckboxProps({ value: prospect.id })} />

                      <Avatar
                        size="sm"
                        name={prospect.name}
                        src={projectPath(`/accounts/${domain}/prospects/${prospect.id}/avatar`)}
                      />

                      <Stack spacing={0.5} flex="1" alignItems="flex-start" isTruncated>
                        <Flex alignItems="center" gap={1.5}>
                          <TextEllipsis fontWeight="medium" maxW="100%" tooltip>
                            <Highlight
                              value={prospect.name}
                              highlight={highlights[prospect.external_id]?.['full_name.analyzed']?.[0]}
                              label="Matches your name search"
                            />
                          </TextEllipsis>

                          <Flex alignItems="center" gap={1}>
                            {prospect.linkedin_url && (
                              <Tooltip label={`https://${prospect.linkedin_url.replace(/https?:\/\//, '')}`}>
                                <Link
                                  display="flex"
                                  flex="none"
                                  alignItems="center"
                                  color="linkedin.700"
                                  isExternal
                                  href={`https://${prospect.linkedin_url.replace(/https?:\/\//, '')}`}
                                >
                                  <LinkedinBoxIcon boxSize="18px" />
                                </Link>
                              </Tooltip>
                            )}
                            {(prospect.salesforce_contact_cache || prospect.salesforce_lead_cache) && (
                              <Tooltip label="View in Salesforce">
                                <Link
                                  href={
                                    prospect.salesforce_contact_cache?.permalink ??
                                    prospect.salesforce_lead_cache?.permalink
                                  }
                                  isExternal
                                >
                                  <SalesforceIcon color="salesforce" boxSize="18px" />
                                </Link>
                              </Tooltip>
                            )}
                            {prospect.hubspot_contact_cache &&
                              !(prospect.salesforce_contact_cache || prospect.salesforce_lead_cache) && (
                                <Tooltip label="View in HubSpot">
                                  <Link href={prospect.hubspot_contact_cache?.permalink} isExternal>
                                    <HubSpotIcon color="hubspot" boxSize="18px" />
                                  </Link>
                                </Tooltip>
                              )}
                            {prospect.profile && (
                              <Tooltip label="View activity in Koala">
                                <Link href={profilePath(prospect.profile)} isExternal>
                                  <KoalaIcon color="purple.500" boxSize="18px" />
                                </Link>
                              </Tooltip>
                            )}
                          </Flex>
                        </Flex>

                        <TextEllipsis fontSize="xs" color="gray.600" maxW="100%" tooltip>
                          <Highlight
                            value={prospect.title}
                            highlight={highlights[prospect.external_id]?.['job_title.analyzed']?.[0]}
                            label="Matches your title keywords"
                          />
                        </TextEllipsis>
                      </Stack>

                      <Flex flex="none" alignItems="center" marginLeft={3} gap={1}>
                        <Button
                          size="sm"
                          variant="outline"
                          colorScheme={prospect.saved ? 'gray' : 'lightPurple'}
                          onClick={prospect.saved ? undefined : () => saveProspects([prospect.id])}
                          isLoading={saving[prospect.id]}
                          minW="70px"
                        >
                          {prospect.saved ? 'Saved' : 'Save'}
                        </Button>

                        {props.apps && (
                          <Flex mr={-1}>
                            <ActionMenu
                              isIconButton
                              prospect={prospect}
                              domain={domain}
                              isLoading={prospects.isLoading}
                              apps={props.apps}
                              account={props.account}
                              persona={props.persona}
                              context="explore"
                              onEmailLoadingChange={(loading) => {
                                setEmailLoadingState((prev) => ({ ...prev, [prospect.id]: loading }))
                              }}
                              onPhoneLoadingChange={(loading) => {
                                setPhoneLoadingState((prev) => ({ ...prev, [prospect.id]: loading }))
                              }}
                              onChange={(updatedProspect) => {
                                setLocalProspects((prev) => {
                                  return prev.map((p) => {
                                    if (p.id === updatedProspect.id) {
                                      return updatedProspect
                                    }
                                    return p
                                  })
                                })
                              }}
                            />
                          </Flex>
                        )}
                      </Flex>
                    </Flex>
                  </Td>
                  {prospect.company_id && prospect?.company ? (
                    <Td width="1px" minW="300px" maxW="300px" paddingLeft={[2.5, 4]}>
                      <Flex alignItems="center" gap={2.5} height="100%">
                        <CompanyBubble
                          name={prospect.company?.name}
                          domain={prospect.company?.domain}
                          href={accountPath({ company: prospect.company })}
                          target="_blank"
                          fontWeight="semibold"
                          _groupHover={{
                            background: 'rgba(255,255,255,0.65)',
                            shadow: 'sm',
                            '& .hover-icon': {
                              display: 'flex',
                              opacity: 1
                            }
                          }}
                        />
                      </Flex>
                    </Td>
                  ) : (
                    <Td color="gray.500" left={0} zIndex={1} bg="white" paddingLeft={[0, 3]}>
                      &mdash;
                    </Td>
                  )}
                  <Td>
                    <HStack fontSize="sm" color="gray.500" spacing={1} maxW="200px" isTruncated>
                      {prospect.subdepartments && (
                        <TextEllipsis maxW="100%" tooltip>
                          {humanize(prospect.subdepartments.join(', ').replace('_', ' '))}
                        </TextEllipsis>
                      )}
                      {prospect.subdepartments.length > 0 && prospect.departments.length > 0 && <Text>/</Text>}
                      {prospect.departments && (
                        <TextEllipsis maxW="100%" tooltip>
                          {humanize(prospect.departments.join(', ').replace('_', ' ') ?? '')}
                        </TextEllipsis>
                      )}
                    </HStack>
                  </Td>
                  <Td>
                    {prospect.email ? (
                      <Flex minWidth="180px" justifyContent="space-between" gap={4} alignItems="center">
                        <Box paddingRight={4}>
                          <Copyable
                            copyText={prospect.email}
                            showToast
                            onCopyToClipboard={() => trackCopyEmail(prospect)}
                          >
                            <TextEllipsis fontSize="sm" maxW="300" tooltip>
                              {prospect.email}
                            </TextEllipsis>
                          </Copyable>
                        </Box>

                        {(prospect.unlock_state === 'unlocked' || prospect.unlock_state === 'auto-unlocked') &&
                          !prospect.profile && (
                            <HStack>
                              {prospect.verification_status !== 'verification_failed' && prospect.email && (
                                <Tooltip label="Enriched via Koala Waterfall">
                                  <Icon as={IconSparkles} color="gray.400" boxSize={4} />
                                </Tooltip>
                              )}
                              {prospect.email && (
                                <Tooltip
                                  label={
                                    prospect.verification_status === 'verified'
                                      ? 'Valid Email'
                                      : prospect.verification_status === 'verification_failed'
                                        ? 'Invalid Email'
                                        : 'Verify Email'
                                  }
                                >
                                  <IconButton
                                    size="sm"
                                    variant="ghost"
                                    colorScheme={
                                      prospect.verification_status === 'unverified'
                                        ? 'gray'
                                        : prospect.verification_status === 'verified'
                                          ? 'green'
                                          : 'red'
                                    }
                                    aria-label="Verify email"
                                    icon={
                                      prospect.verification_status === 'verified' ? (
                                        <Icon as={IconRosetteDiscountCheckFilled} />
                                      ) : (
                                        <Icon as={IconCircleDashedCheck} />
                                      )
                                    }
                                    isLoading={verificationLoadingState[prospect.id]}
                                    onClick={() => {
                                      if (prospect.verification_status === 'verified') {
                                        toast.info('Email has already been verified')
                                      } else {
                                        onVerifyEmail(prospect)
                                      }
                                    }}
                                  >
                                    {prospect.verification_status === 'unverified'
                                      ? 'Verify'
                                      : prospect.verification_status === 'verified'
                                        ? 'Verified'
                                        : ''}
                                  </IconButton>
                                </Tooltip>
                              )}
                            </HStack>
                          )}
                      </Flex>
                    ) : prospect.unlock_state === 'not_found' || emailNotFound[prospect.id] ? (
                      <Flex color="gray.500" gap={1.5} alignItems="center">
                        No email found
                        <HelpTooltip>
                          Koala couldn't find an email for this prospect in any of our data sources.
                        </HelpTooltip>
                      </Flex>
                    ) : (
                      <Flex alignItems="center" gap={1}>
                        <Button
                          aria-label="Find email address"
                          size="sm"
                          variant="outline"
                          bg="white"
                          color="gray.600"
                          borderColor="gray.200"
                          _hover={{
                            color: 'purple.600',
                            borderColor: 'purple.300',
                            '& .chakra-button__icon svg': { opacity: 1 }
                          }}
                          leftIcon={<Icon as={IconMailSearch} boxSize={3.5} opacity={0.6} />}
                          iconSpacing={1.5}
                          onClick={() => onUnlockEmail(prospect)}
                          isLoading={emailLoadingState[prospect.id]}
                          loadingText="Enriching..."
                        >
                          Find email
                        </Button>
                        <HelpTooltip
                          visibility="hidden"
                          pointerEvents="none"
                          _groupHover={{ visibility: 'visible', pointerEvents: 'unset' }}
                        >
                          <Stack spacing="4" p="2">
                            <Heading size="xs" fontWeight="semibold">
                              Unlock prospect
                            </Heading>
                            <Text fontSize="sm" color="gray.600" whiteSpace="pre-wrap" lineHeight="1.4">
                              Koala couldn't find an email for this prospect in any of our data sources. Click to search
                              for it using Koala Waterfall.
                            </Text>
                            <Text
                              whiteSpace={'pre-wrap'}
                              fontSize="xs"
                              lineHeight="1.4"
                              p="4"
                              bg="purple.50"
                              color="gray.700"
                            >
                              Note: We're offering unlimited Koala Waterfall enrichment credits to all beta testers of
                              Prospecting.
                            </Text>
                            <Button
                              size="sm"
                              colorScheme={'purple'}
                              onClick={() => onUnlockEmail(prospect)}
                              leftIcon={<IconMailSearch size="18" />}
                              isLoading={emailLoadingState[prospect.id]}
                            >
                              Find email
                            </Button>
                          </Stack>
                        </HelpTooltip>
                      </Flex>
                    )}
                  </Td>
                  <Td>
                    {prospect.phone_numbers && prospect.phone_numbers.mobile && (
                      <Flex minWidth="150px" justifyContent="space-between" gap={4}>
                        <Box paddingRight={4}>
                          <Copyable
                            copyText={prospect.phone_numbers.mobile}
                            css={{ fontVariantNumeric: 'tabular-nums' }}
                            showToast
                            onCopyToClipboard={() => trackCopyPhone(prospect)}
                          >
                            {prospect.phone_numbers.mobile}
                          </Copyable>
                        </Box>

                        {prospect.phone_state === 'unlocked' && !prospect.profile && (
                          <Tooltip label="Enriched via Koala Waterfall">
                            <Icon as={IconSparkles} color="gray.400" boxSize={4} />
                          </Tooltip>
                        )}
                      </Flex>
                    )}

                    {prospect.phone_state === 'not_found' ? (
                      <Flex alignItems="center" gap={1.5} color="gray.500">
                        No phone number found
                        <HelpTooltip>
                          Koala couldn't find a phone number for this prospect in any of our data sources.
                        </HelpTooltip>
                      </Flex>
                    ) : prospect.phone_state === 'locked' || prospect.phone_state === null ? (
                      <HStack>
                        <Button
                          aria-label="Find phone number"
                          size="sm"
                          variant="outline"
                          bg="white"
                          color="gray.600"
                          borderColor="gray.200"
                          _hover={{
                            color: 'purple.600',
                            borderColor: 'purple.300',
                            '& .chakra-button__icon svg': { opacity: 1 }
                          }}
                          leftIcon={<Icon as={IconPhonePlus} boxSize={3.5} opacity={0.6} />}
                          iconSpacing={1.5}
                          onClick={() => onUnlockPhone(prospect)}
                          isLoading={phoneLoadingState[prospect.id]}
                        >
                          Find phone
                        </Button>
                      </HStack>
                    ) : null}
                  </Td>
                  <Td>
                    <Text fontSize="sm" color="gray.500">
                      {[prospect.city, prospect.region, prospect.country]
                        .filter(Boolean)
                        .map((s) => humanize(s || ''))
                        .join(', ')}
                    </Text>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      ) : noData && hasFilters ? (
        <EmptyState
          icon={IconUserSearch}
          size="sm"
          heading="No prospects found"
          description="We could not find any prospects for this company matching these filters."
          ctaText={props.persona ? undefined : 'Clear Filters'}
          onClick={() => {
            facets.clearFilters()
          }}
        />
      ) : noData && !hasFilters ? (
        <EmptyState
          icon={IconUserSearch}
          size="sm"
          heading="No prospects found"
          description={`We could not find any prospects for this company${
            props.persona ? ' matching this persona' : ''
          }.`}
        />
      ) : null}

      {hasData && pageMeta && (
        <TableFooter
          page={page}
          sticky
          scrollToTop={false}
          setPage={facets.setPage}
          pageMeta={pageMeta}
          isLoading={prospects.isLoading}
          paddingX={4}
        />
      )}

      <BulkActionBar selectionCount={checkboxes.value?.length ?? 0} onRemoveSelection={() => checkboxes.setValue([])}>
        {selectedAllSaved ? (
          <Button
            size="sm"
            variant="outline"
            leftIcon={<IconAddressBookOff size={16} />}
            iconSpacing={1.5}
            isDisabled={checkboxes.value.length === 0}
            onClick={() => unsaveProspects(checkboxes.value as string[])}
          >
            Unsave Prospects
          </Button>
        ) : (
          <Button
            size="sm"
            variant="outline"
            leftIcon={<IconAddressBook size={16} />}
            iconSpacing={1.5}
            isDisabled={checkboxes.value.length === 0 || selectedAllSaved}
            onClick={() => saveProspects(checkboxes.value as string[])}
          >
            Save Prospects
          </Button>
        )}

        {!selectedAllEnriched && (
          <Button size="sm" variant="outline" onClick={enrichSelected}>
            Enrich
          </Button>
        )}

        <Button size="sm" variant="outline" onClick={addToCrm.onOpen}>
          Add to CRM
        </Button>

        <Button size="sm" variant="outline" onClick={addToSequence.onOpen} isDisabled>
          Add to Sequence
        </Button>

        <Button
          size="sm"
          variant="outline"
          leftIcon={<IconTableDown size={16} />}
          iconSpacing={1.5}
          onClick={() => {
            if (!company?.domain) {
              return
            }
            const url = filteredProspectsPath(company?.domain, {
              filters,
              format: 'csv',
              persona: persona?.id,
              search: search,
              only_saved: savedProspectsSelected,
              ids: checkboxes.value as string[],
              page: page
            })
            window.open(url)
          }}
        >
          Export
        </Button>
      </BulkActionBar>

      <AddToCrmModal {...addToCrm} apps={props.apps} selectedIds={selected} onImport={onImport} />
      <AddToSequenceModal {...addToSequence} apps={props.apps} selectedIds={selected} onImport={onImport} />
    </Box>
  )
}

const addToCrmActions = [
  {
    label: (
      <Flex gap={2} alignItems="center" justifyContent="space-between">
        <Text fontWeight="medium" fontSize="sm" lineHeight="18px">
          Add as Leads in Salesforce
        </Text>
        <SfLeadIcon boxSize={4} />
      </Flex>
    ),
    value: 'salesforce-leads'
  },
  {
    label: (
      <Flex gap={2} alignItems="center" justifyContent="space-between">
        <Text fontWeight="medium" fontSize="sm" lineHeight="18px">
          Add as Contacts in Salesforce
        </Text>
        <SfContactIcon boxSize={4} />
      </Flex>
    ),
    value: 'salesforce-contacts'
  },
  {
    label: (
      <Flex gap={2} alignItems="center" justifyContent="space-between">
        <Text fontWeight="medium" fontSize="sm" lineHeight="18px">
          Add as Contacts in HubSpot
        </Text>
        <HubSpotIcon boxSize={4} color="hubspot" />
      </Flex>
    ),

    value: 'hubspot-contacts'
  }
]

interface AddToCrmProps extends UseDisclosureProps {
  apps: Apps
  selectedIds: string[]
  onImport: (prospects: Partial<Prospect>[]) => void
}

function AddToCrmModal(props: AddToCrmProps) {
  const disclosure = useDisclosure(props)
  const [action, setAction] = useState<string | null>(null)
  const [submitting, setSubmitting] = useState(false)

  const hasHubspot = useMemo(() => props.apps?.['Apps::Hubspot::App']?.valid || false, [props.apps])
  const hasSalesforce = useMemo(() => props.apps?.['Apps::Salesforce::App']?.valid || false, [props.apps])
  const actions = useMemo(
    () =>
      addToCrmActions.map((a) => ({
        ...a,
        isDisabled: (a.value.includes('hubspot') && !hasHubspot) || (a.value.includes('salesforce') && !hasSalesforce)
      })),
    [hasHubspot, hasSalesforce]
  )

  const onImport = props.onImport
  const onClose = disclosure.onClose
  const { mutateAsync: importProspectsToCrm } = useImportProspectsToCrm()

  const onSubmit = useCallback(async () => {
    let type: 'contact' | 'lead' | undefined
    let app_module: 'Salesforce' | 'Hubspot' | undefined

    if (action === 'salesforce-leads') {
      app_module = 'Salesforce'
      type = 'lead'
    } else if (action === 'salesforce-contacts') {
      app_module = 'Salesforce'
      type = 'contact'
    } else if (action === 'hubspot-contacts') {
      app_module = 'Hubspot'
    }

    if (!app_module) {
      return
    }

    try {
      setSubmitting(true)
      const res = await importProspectsToCrm({ prospect_ids: props.selectedIds, app_module, type })
      onImport(res.prospects)
      onClose()
      toast.success(`Prospects imported to ${app_module}`)
    } catch (_err) {
      toast.error('Failed to import prospects to CRM')
    } finally {
      setSubmitting(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.selectedIds, action, importProspectsToCrm, onImport, onClose])

  return (
    <Modal {...disclosure} size="lg">
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalHeader fontSize="md">Add to CRM</ModalHeader>
        <ModalBody>
          <FormControl size="sm" isRequired>
            <CardRadioGroup
              size="sm"
              iconPlacement="left"
              direction="column"
              showUncheckedIcon
              value={action || ''}
              onChange={setAction}
              options={actions}
            />
          </FormControl>
        </ModalBody>
        <ModalFooter gap={3}>
          <Button size="sm" variant="outline" onClick={disclosure.onClose}>
            Cancel
          </Button>
          <Button size="sm" colorScheme="purple" onClick={onSubmit} isLoading={submitting} isDisabled={!action}>
            Add to CRM
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
// Add to sequence
const addToSequenceActions = [
  {
    label: (
      <Flex gap={2} alignItems="center" justifyContent="space-between">
        <Text fontWeight="medium" fontSize="sm" lineHeight="18px">
          Add to Sequence in Outreach
        </Text>
        <CompanyAvatar size="14px" domain={'outreach.io'} />
      </Flex>
    ),
    value: 'outreach-sequence'
  },
  {
    label: (
      <Flex gap={2} alignItems="center" justifyContent="space-between">
        <Text fontWeight="medium" fontSize="sm" lineHeight="18px">
          Add to Sequence in Apollo
        </Text>
        <CompanyAvatar size="14px" domain={'apollo.io'} />
      </Flex>
    ),
    value: 'apollo-sequence'
  },
  {
    label: (
      <Flex gap={2} alignItems="center" justifyContent="space-between">
        <Text fontWeight="medium" fontSize="sm" lineHeight="18px">
          Add to Cadence in Salesloft
        </Text>
        <CompanyAvatar size="14px" domain={'salesloft.com'} />
      </Flex>
    ),
    value: 'salesloft-cadence'
  }
]

interface AddToSequenceProps extends UseDisclosureProps {
  apps: Apps
  selectedIds: string[]
  onImport: (prospects: Partial<Prospect>[]) => void
}

function AddToSequenceModal(props: AddToSequenceProps) {
  const disclosure = useDisclosure(props)
  const [action, setAction] = useState<string | null>(null)
  const [submitting, setSubmitting] = useState(false)

  const hasApollo = useMemo(() => props.apps?.['Apps::Apollo::App']?.valid || false, [props.apps])
  const hasOutreach = useMemo(() => props.apps?.['Apps::Outreach::App']?.valid || false, [props.apps])
  const hasSalesloft = useMemo(() => props.apps?.['Apps::Salesloft::App']?.valid || false, [props.apps])
  const actions = useMemo(
    () =>
      addToSequenceActions.map((a) => ({
        ...a,
        isDisabled:
          (a.value.includes('apollo') && !hasApollo) ||
          (a.value.includes('outreach') && !hasOutreach) ||
          (a.value.includes('salesloft') && !hasSalesloft)
      })),
    [hasSalesloft, hasApollo, hasOutreach]
  )

  // const onImport = props.onImport
  // const onClose = disclosure.onClose
  //
  const onSubmit = useCallback(async () => {
    setSubmitting(true)
    setSubmitting(false)
  }, [])

  return (
    <Modal {...disclosure} size="lg">
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalHeader fontSize="md">Add to Sequence</ModalHeader>
        <ModalBody>
          <FormControl size="sm" isRequired>
            <CardRadioGroup
              size="sm"
              iconPlacement="left"
              direction="column"
              showUncheckedIcon
              value={action || ''}
              onChange={setAction}
              options={actions}
            />
          </FormControl>
        </ModalBody>
        <ModalFooter gap={3}>
          <Button size="sm" variant="outline" onClick={disclosure.onClose}>
            Cancel
          </Button>
          <Button size="sm" colorScheme="purple" onClick={onSubmit} isLoading={submitting} isDisabled={!action}>
            Add to Sequence
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

function Highlight(props: { value: string | null | undefined; label?: string; highlight?: string | undefined }) {
  const parts = (props.highlight || props.value || '').split(/(<em>.*?<\/em>)/g)

  return (
    <>
      {parts.map((part, idx) => {
        const padLeft = part.startsWith(' ')
        const padRight = part.endsWith(' ')
        const key = part + idx

        if (part.startsWith('<em>') && part.endsWith('</em>')) {
          return (
            <Tooltip key={key} label={props.label || 'Matches your keywords'} hasArrow arrowSize={6} openDelay={400}>
              <Text as="span" bg="background.highlighter">
                {humanize(part.replace(/<em>|<\/em>/g, ''), false)}
              </Text>
            </Tooltip>
          )
        }

        return (
          <Text key={key} as="span">
            {padLeft && ' '}
            {humanize(part, false)}
            {padRight && ' '}
          </Text>
        )
      })}
    </>
  )
}
